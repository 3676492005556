import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonInput,
    IonLabel,
    IonTabButton,
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonList,
    IonSpinner,
} from '@ionic/react';
import React from 'react';
import API from '../API';
import UserService from '../UserService';
import { chatbubbleEllipses, logIn } from 'ionicons/icons';
import branch from 'branch-sdk';

export default class extends React.Component<any> {
    state = {
        links: [],
        linkIsGenerating: false,
    };

    componentDidMount() {
        this.getLinks();
    }

    async createLink() {
        if (this.state.linkIsGenerating) {
            return;
        }

        this.setState({
            linkIsGenerating: true,
        });
        let res = await API.createLink();

        let linkId = res.data;
        branch.link(
            {
                campaign: 'TakoutPartners',
                channel: 'Partners Program',
                feature: '',
                stage: '',
                tags: [],
                data: {
                    partner_id: UserService.getUserId().toString(),
                    linkId: linkId,
                },
            },
            (error, link) => {
                if (error) {
                    alert(error);
                } else {
                    console.log(link);
                    API.updateLink(linkId, link).then((responce) => {
                        this.setState({
                            linkIsGenerating: false,
                        });
                        this.setState({
                            links: responce.data,
                        });
                    });
                }
            },
        );
    }

    async getLinks() {
        let responce = await API.getLinks();

        this.setState({
            links: responce.data,
        });
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton />
                        </IonButtons>
                        <IonTitle>Ссылки</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={this.createLink.bind(this)}>
                                {this.state.linkIsGenerating ? <IonSpinner></IonSpinner> : <p>Создать ссылку</p>}
                            </IonButton>
                            <IonButton onClick={this.getLinks.bind(this)}>Обновить</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        {this.state.links.map((link: any) => {
                            return (
                                <IonItem key={link.id}>
                                    <IonLabel>
                                        <h2>{link.link ? link.link : 'Ссылка отсутствует'}</h2>
                                        <p>
                                            Создан:{' '}
                                            {Intl.DateTimeFormat('ru-RU', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: '2-digit',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                            }).format(new Date(link.created))}
                                        </p>
                                    </IonLabel>
                                    <p slot="end">Регистраций: {link.registrations}</p>
                                </IonItem>
                            );
                        })}
                    </IonList>
                </IonContent>
            </IonPage>
        );
    }
}
