import Menu from './components/Menu';
import React from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import AuthPage from './pages/AuthPage';
import StatisticsPage from './pages/StatisticsPage';
import PromocodesPage from './pages/PromocodesPage';
import LinksPage from './pages/LinksPage';
import UsersPage from './pages/UsersPage';

const App: React.FC = () => {
    return (
        <IonApp>
            <IonReactRouter>
                <IonSplitPane contentId="main">
                    <Menu />
                    <IonRouterOutlet id="main">
                        <Redirect from="/" to="/statistics" exact />
                        <Route path="/statistics" component={StatisticsPage} exact />
                        <Route path="/links" component={LinksPage} exact />
                        <Route path="/promocodes" component={PromocodesPage} exact />
                        <Route path="/users" component={UsersPage} exact />
                    </IonRouterOutlet>
                </IonSplitPane>
            </IonReactRouter>
            <IonReactRouter>
                <Route path="/auth" component={AuthPage} exact />
            </IonReactRouter>
        </IonApp>
    );
};

export default App;
