import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonLabel,
    IonItemDivider,
    IonDatetime,
    IonRow,
    IonCol,
} from '@ionic/react';
import React from 'react';
import API from '../API';
import UserService from '../UserService';
import { AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Area } from 'recharts';

export default class extends React.Component<any> {
    state = {
        dates: [],
        total: {
            revenue: 0,
            promocodeActivates: 0,
            linkActivates: 0,
        },
    };

    filterStartDate = new Date().toISOString();
    filterEndDate = new Date().toISOString();

    async getData() {
        let responce = await API.getStatistics(this.filterStartDate.split('T')[0], this.filterEndDate.split('T')[0]);

        this.setState({
            dates: responce.data,
            total: responce.total,
        });
    }

    componentDidMount() {
        if (UserService.isLogged()) {
            this.getData();
        }else{
            UserService.dropUserData();
            document.location.href = "/auth";
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton />
                        </IonButtons>
                        <IonTitle>Пользователи</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonItemDivider>Интервал времени</IonItemDivider>
                    <IonItem>
                        <IonLabel position="floating">Выберите дату от</IonLabel>
                        <IonDatetime
                            displayFormat="MM/DD/YYYY"
                            value={this.filterStartDate}
                            onIonChange={(event) => {
                                this.filterStartDate = event.detail.value ?? this.filterStartDate;
                                this.getData();
                            }}></IonDatetime>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="floating">Выберите дату до</IonLabel>
                        <IonDatetime
                            displayFormat="MM/DD/YYYY"
                            value={this.filterEndDate}
                            onIonChange={(event) => {
                                this.filterEndDate = event.detail.value ?? this.filterEndDate;
                                this.getData();
                            }}></IonDatetime>
                    </IonItem>

                    <div className="ion-padding">
                        <IonRow>
                            <IonCol size="4">
                                <IonLabel>
                                    <p>Регистраций с ссылок</p>
                                    <h1>{this.state.total.linkActivates}</h1>
                                </IonLabel>
                            </IonCol>
                            <IonCol size="4">
                                <IonLabel>
                                    <p>Активаций промокодов</p>
                                    <h1>{this.state.total.promocodeActivates}</h1>
                                </IonLabel>
                            </IonCol>
                            <IonCol size="4">
                                <IonLabel>
                                    <p>Заработано</p>
                                    <h1>{this.state.total.revenue}</h1>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                    </div>
                    <AreaChart
                        width={900}
                        height={400}
                        data={this.state.dates}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Area
                            type="monotone"
                            dataKey="lastActiveDateCount"
                            stroke="#0000ff"
                            fill="#0000ff"
                            name="Регистраций с ссылок"
                        />
                        <Area
                            type="monotone"
                            dataKey="videocallsCount"
                            stroke="#ff0066"
                            fill="#ff0066"
                            name="Активаций промокодов"
                        />
                        <Area
                            type="monotone"
                            dataKey="revenue"
                            stroke="#179600"
                            fill="#179600"
                            name="Заработано"
                        />
                    </AreaChart>
                </IonContent>
            </IonPage>
        );
    }
}
