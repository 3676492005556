import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonLabel,
    IonItemDivider,
    IonDatetime,
    IonRow,
    IonCol,
    IonList,
} from '@ionic/react';
import React from 'react';
import API from '../API';
import UserService from '../UserService';

export default class extends React.Component<any> {
    state = {
        users: [],
    };

    filterStartDate = new Date().toISOString();
    filterEndDate = new Date().toISOString();

    async getData() {
        let responce = await API.getPartnerUsers(this.filterStartDate.split('T')[0], this.filterEndDate.split('T')[0]);

        this.setState({
            users: responce.data,
        });
    }

    componentDidMount() {
        if (UserService.isLogged()) {
            this.getData();
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton />
                        </IonButtons>
                        <IonTitle>Статистика</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonItemDivider>Интервал времени</IonItemDivider>
                    <IonItem>
                        <IonLabel position="floating">Выберите дату от</IonLabel>
                        <IonDatetime
                            displayFormat="MM/DD/YYYY"
                            value={this.filterStartDate}
                            onIonChange={(event) => {
                                this.filterStartDate = event.detail.value ?? this.filterStartDate;
                                this.getData();
                            }}></IonDatetime>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="floating">Выберите дату до</IonLabel>
                        <IonDatetime
                            displayFormat="MM/DD/YYYY"
                            value={this.filterEndDate}
                            onIonChange={(event) => {
                                this.filterEndDate = event.detail.value ?? this.filterEndDate;
                                this.getData();
                            }}></IonDatetime>
                    </IonItem>

                    <IonList>
                        {this.state.users.map((user: any) => {
                            return (
                                <IonItem key={user.id}>
                                    <IonLabel>
                                        <h3>{user.name}</h3>
                                        <p>{user.email}</p>
                                        <p>
                                            Дата регистрации:{' '}
                                            {Intl.DateTimeFormat('ru-RU', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: '2-digit',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                            }).format(new Date(user.created))}
                                        </p>
                                    </IonLabel>
                                </IonItem>
                            );
                        })}
                    </IonList>
                </IonContent>
            </IonPage>
        );
    }
}
