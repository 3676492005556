import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonInput,
    IonLabel,
    IonTabButton,
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonButton,
} from '@ionic/react';
import React from 'react';
import API from '../API';
import UserService from '../UserService';
import { chatbubbleEllipses, logIn } from 'ionicons/icons';

export default class extends React.Component<any> {
    state = {
        codeRequested: false,
    };

    email = '';
    code = '';

    async requestCode() {
        console.log("asd");
        let responce = await API.requestCode(this.email);

        if (responce.success) {
            this.setState({
                codeRequested: true,
            });
            console.log(responce);
            console.log(this.state);
        } else {
            alert(responce.error);
        }
    }

    async confirmCode() {
        let responce = await API.confirmCode(this.email, this.code);

        console.log(responce);

        if (responce.success) {
            UserService.setAccessToken(responce.data.access_token);
            UserService.setUser(responce.data);
            document.location.href = "/";
        } else {
            alert(responce.error);
        }
    }

    render() {
        return (
            <IonPage>
                <IonContent>
                    <IonCard style={{ marginTop: '35vh', paddingBottom: 32 }}>
                        <IonCardHeader>
                            <IonCardSubtitle>Авторизация</IonCardSubtitle>
                            <IonCardTitle>Talkout Partners</IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                            <IonLabel>Войдите в систему</IonLabel>
                            <IonItem>
                                <IonLabel position="floating">Email</IonLabel>
                                <IonInput
                                    placeholder="mail@example.com"
                                    defaultValue=""
                                    onIonChange={(event) => (this.email = event.detail.value!)}></IonInput>
                            </IonItem>
                            {this.state.codeRequested ? (
                                <IonItem>
                                    <IonLabel position="floating">Код подтверждения</IonLabel>
                                    <IonInput
                                        placeholder="XXX-XXX"
                                        defaultValue=""
                                        onIonChange={(event) => (this.code = event.detail.value!)}></IonInput>
                                </IonItem>
                            ) : (
                                false
                            )}

                            {!this.state.codeRequested ? (
                                <IonButton onClick={() => { this.requestCode()}}>
                                    <IonIcon icon={chatbubbleEllipses}></IonIcon>
                                    <IonLabel>Запросить код</IonLabel>
                                </IonButton>
                            ) : (
                                <IonButton onClick={() => this.confirmCode()}>
                                    <IonIcon icon={logIn}></IonIcon>
                                    <IonLabel>Подтвердить код</IonLabel>
                                </IonButton>
                            )}
                        </IonCardContent>
                    </IonCard>
                </IonContent>
            </IonPage>
        );
    }
}
