import { serverAddress } from './Constants';
import UserService from './UserService';

export default class {
    static headers = { 'Content-type': 'application/json' };

    static errorMiddleware(request: any) {
        if (request.success == false) {
            if (request.code == 1) {
                UserService.dropUserData();
                alert('Неккоректный токен. Нужно переавторизоваться');
                document.location.href = '/auth';
            } else {
                alert(JSON.stringify(request.error));
            }
        } else {
            return request;
        }
    }

    static getBody(data: any) {
        return JSON.stringify({
            ...data,
            locale: 'ru',
        });
    }

    static async requestCode(email: any) {
        let responce = await fetch(serverAddress + 'auth/requestCode', {
            method: 'POST',
            body: this.getBody({
                authType: 'email',
                email: email,
            }),
            headers: this.headers,
        });

        return await responce.json();
    }

    static async confirmCode(email: any, code: any) {
        let responce = await fetch(serverAddress + 'auth/confirmCode', {
            method: 'POST',
            body: this.getBody({
                authType: 'email',
                email: email,
                code: code,
            }),
            headers: this.headers,
        });

        return await responce.json();
    }

    static async getLinks() {
        let responce = await fetch(serverAddress + 'partner/link/list', {
            method: 'POST',
            body: this.getBody({
                accessToken: UserService.getToken(),
            }),
            headers: this.headers,
        });
        return this.errorMiddleware(await responce.json());
    }

    static async createLink() {
        let responce = await fetch(serverAddress + 'partner/link/create', {
            method: 'POST',
            body: this.getBody({
                accessToken: UserService.getToken(),
            }),
            headers: this.headers,
        });
        return this.errorMiddleware(await responce.json());
    }

    static async updateLink(linkId: any, link: any) {
        let responce = await fetch(serverAddress + 'partner/link/update', {
            method: 'POST',
            body: this.getBody({
                accessToken: UserService.getToken(),
                linkId: linkId,
                link: link,
            }),
            headers: this.headers,
        });
        return this.errorMiddleware(await responce.json());
    }

    static async createPromocode(promocode: any) {
        let responce = await fetch(serverAddress + 'partner/promocode/create', {
            method: 'POST',
            body: this.getBody({
                accessToken: UserService.getToken(),
                promocode: promocode,
            }),
            headers: this.headers,
        });
        return this.errorMiddleware(await responce.json());
    }

    static async getPromocodes() {
        let responce = await fetch(serverAddress + 'partner/promocode/list', {
            method: 'POST',
            body: this.getBody({
                accessToken: UserService.getToken(),
            }),
            headers: this.headers,
        });
        return this.errorMiddleware(await responce.json());
    }

    static async getStatistics(startDate: any, endDate: any) {
        let responce = await fetch(serverAddress + 'partner/statistics', {
            method: 'POST',
            body: this.getBody({
                accessToken: UserService.getToken(),
                startDate: startDate,
                endDate: endDate,
            }),
            headers: this.headers,
        });
        return this.errorMiddleware(await responce.json());
    }

    static async getPartnerUsers(startDate: any, endDate: any) {
      let responce = await fetch(serverAddress + 'partner/users', {
          method: 'POST',
          body: this.getBody({
              accessToken: UserService.getToken(),
              startDate: startDate,
              endDate: endDate,
          }),
          headers: this.headers,
      });
      return this.errorMiddleware(await responce.json());
  }

    static async getUser() {
        let responce = await fetch(serverAddress + 'user/profile', {
            method: 'POST',
            body: this.getBody({
                accessToken: UserService.getToken(),
            }),
            headers: this.headers,
        });
        return this.errorMiddleware(await responce.json());
    }
}
