import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonTabButton,
} from '@ionic/react';

import React, { useState, useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import {
    archiveOutline,
    archiveSharp,
    bookmarkOutline,
    heartOutline,
    heartSharp,
    mailOutline,
    mailSharp,
    paperPlaneOutline,
    paperPlaneSharp,
    trashOutline,
    trashSharp,
    warningOutline,
    warningSharp,
    analyticsOutline,
    analyticsSharp,
    linkOutline,
    linkSharp,
    codeOutline,
    codeSharp,
    personOutline,
    personSharp,
} from 'ionicons/icons';
import './Menu.css';
import UserService from '../UserService';
import API from '../API';

interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
}

const appPages: AppPage[] = [
    {
        title: 'Статистика',
        url: '/statistics',
        iosIcon: analyticsOutline,
        mdIcon: analyticsSharp,
    },
    {
        title: 'Ссылки',
        url: '/links',
        iosIcon: linkOutline,
        mdIcon: linkSharp,
    },
    {
        title: 'Промокоды',
        url: '/promocodes',
        iosIcon: codeOutline,
        mdIcon: codeSharp,
    },
    {
        title: 'Пользователи',
        url: '/users',
        iosIcon: personOutline,
        mdIcon: personSharp,
    },
];

class Menu extends React.Component<any> {
    state = {
        user: UserService.getUser(),
    };

    componentDidMount() {
        if (UserService.isLogged()) {
            API.getUser().then((res: any) => {
                UserService.setUser(res.data);
                this.setState({
                    user: res.data,
                });
            });
        }
    }

    render() {
        return (
            <IonMenu contentId="main" type="overlay">
                <IonContent>
                    <IonList id="inbox-list">
                        <IonListHeader>Talkout Partners</IonListHeader>
                        <IonNote>
                            {this.state.user.email} | RevShare {this.state.user.partner_revenue}%
                        </IonNote>
                        {appPages.map((appPage, index) => {
                            return (
                                <IonMenuToggle key={index} autoHide={false}>
                                    <IonItem
                                        className={this.props.location.pathname === appPage.url ? 'selected' : ''}
                                        routerLink={appPage.url}
                                        routerDirection="none"
                                        lines="none"
                                        detail={false}>
                                        <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                                        <IonLabel>{appPage.title}</IonLabel>
                                    </IonItem>
                                </IonMenuToggle>
                            );
                        })}
                    </IonList>
                    <IonItem
                        color="danger"
                        onClick={() => {
                            UserService.dropUserData();
                            document.location.href = '/auth';
                        }}>
                        Выйти с аккаунта
                    </IonItem>
                </IonContent>
            </IonMenu>
        );
    }
}

export default withRouter(Menu);
