const forceProd = true;

const isLocalhost = document.location.hostname == 'localhost';

export const serverAddress =
    isLocalhost && !forceProd ? 'http://localhost:8080/api/v1/' : 'https://api2.talkout.me/api/v1/';
export const signallingAddress = isLocalhost && !forceProd ? 'http://localhost:8080/' : 'https://api2.talkout.me/';
export const branchKey =
    isLocalhost && !forceProd
        ? 'key_test_pnLI4o63Asq5ecqIxpY39hkdFAlvI8Xr'
        : 'key_live_ffLJ6g62EwsZdduHuaX2NkeoxqlFNW8f';
