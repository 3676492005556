import { serverAddress } from './Constants';
import API from './API';

export default class UserService {
    static dropUserData() {
        localStorage.removeItem('token');
    }

    static setAccessToken(token: string) {
        localStorage.setItem('token', token);
    }

    static getToken(): string {
        return localStorage.getItem('token') || '';
    }

    static getUserId(): number {
        return parseInt(this.getToken().split('#')[0]);
    }

    static isLogged(): boolean {
        return this.getToken().length != 0;
    }

    static setUser(user: any) {
        localStorage.setItem('user', JSON.stringify(user));
    }

    static getUser(): any {
        return JSON.parse(localStorage.getItem('user') ?? '{}');
    }
}
