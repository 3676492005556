import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonInput,
    IonLabel,
    IonTabButton,
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonAlert,
    IonSpinner,
    IonList,
} from '@ionic/react';
import React from 'react';
import API from '../API';
import UserService from '../UserService';
import { chatbubbleEllipses, logIn } from 'ionicons/icons';

export default class extends React.Component<any> {
    state = {
        createPromocodeOpened: false,
        promocodes: [],
    };

    componentDidMount() {
        this.getPromocodes();
    }

    async getPromocodes() {
        let res = await API.getPromocodes();
        this.setState({
            promocodes: res.data,
        });
    }

    render() {
        return (
            <IonPage>
                <IonAlert
                    isOpen={this.state.createPromocodeOpened}
                    header={'Создание промокода'}
                    subHeader="Придумайте уникальный промокод"
                    inputs={[
                        {
                            name: 'promocode',
                            placeholder: 'Ваш промокод',
                        },
                    ]}
                    buttons={[
                        {
                            text: 'Отмена',
                            handler: async (alert) => {
                                this.setState({
                                    createPromocodeOpened: false,
                                });
                            },
                        },
                        {
                            text: 'Создать',
                            handler: async (alert) => {
                                let promocode = alert.promocode;
                                await API.createPromocode(promocode);

                                let res = await API.getPromocodes();
                                this.setState({
                                    createPromocodeOpened: false,
                                    promocodes: res.data,
                                });
                            },
                        },
                    ]}
                />
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton />
                        </IonButtons>
                        <IonTitle>Промокоды</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => this.setState({ createPromocodeOpened: true })}>
                                Создать промокод
                            </IonButton>
                            <IonButton onClick={this.getPromocodes.bind(this)}>Обновить</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        {this.state.promocodes.map((promocode: any) => {
                            return (
                                <IonItem key={promocode.id}>
                                    <IonLabel>
                                        <h2>Промокод: {promocode.promocode}</h2>
                                        <p>
                                            Создан:{' '}
                                            {Intl.DateTimeFormat('ru-RU', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: '2-digit',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                            }).format(new Date(promocode.created))}
                                        </p>
                                        <p>Пользователь получит при активации {Math.round(promocode.welcome_bonus / 100)} евро</p>
                                    </IonLabel>
                                    <p slot="end">Активаций: {promocode.activations}</p>
                                </IonItem>
                            );
                        })}
                    </IonList>
                </IonContent>
            </IonPage>
        );
    }
}
